/*
    Components: Styleguide
    ---
    Styleguide specific scaffolding and demo styles
*/

.c-section-title {
    color: palette(color-neutral);
    border-bottom: 1px solid palette(color-neutral);
    @include padding-bottom(1);
    @include padding-top(1);
    @include text-dimensions($alpha-font-size,1,2);
}

.c-scss {
    display: block;
    color: palette(color-neutral,dark);
    background-color: palette(color-neutral,light);
    border: $border-width $border-style palette(color-neutral);
    border-radius: $radius;
    @include margin-tb(0, 1);
    @include padding-tb(.5, .5);
    @include padding-lr(1,1);
}

.c-toggle {
    background: white;
    border: 0;
    outline: 0;
    color: palette(color-alpha);

    > .c-symbol {
        position: relative;
        top: -2px;
        transition: transform .1s ease-in-out;
    }

    &.is-toggled > .c-symbol {
        transform: rotate(90deg);
    }
}

.c-swatch {
    width: 100%;
    height: 60px;
    box-shadow: 0 0 1px 3px palette(color-neutral,x-light), 0 0 2px 4px palette(color-neutral,light);
}

.c-swatch-caption {
    color: palette(color-neutral);
    min-height: 55px;
    @include text-dimensions(14,.8,0,.5,.3);
}
