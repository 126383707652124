/*
    Components: Cards
    ---
    A card displays site content in a manner similar to a playing card.
*/

.c-card {
    position: relative;
    
    // @include padding-tb(1,1);
    // @include padding-lr(1,1);
    @include margin-bottom(.5);

    img {
        transform: scale(1);
        transition: transform 300ms cubic-bezier(0.16, 1, 0.3, 1);
    }

    &:hover {
        img {
            transform: scale(1.03);
        }
    }
}
    // Reset bottom margins on children to make equal paddings possible
    .c-card > *:last-child,
    .c-card > *:last-child > *:last-child,
    .c-card > *:last-child > *:last-child > *:last-child {
        margin-bottom: 0;
    }

.c-card__link {
    // position: relative;

    &:after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }
}

.c-card--case {
    @include margin-bottom(2);
}

.c-card--alpha {
    color: palette(color-neutral,dark);
    background-color: palette(color-neutral,light);
    border: $border-width $border-style palette(color-neutral,base);
    border-radius: $radius;
}
