/*
    Objects: Grid
    ---
    Grid system objects, works together with utility width classes
*/

.o-container {
    margin-left: auto;
    margin-right: auto;
    max-width: $grid-max-width;
}

// No gutters

.o-grid {
    @include clearfix;

    // Remove clearfix when using flexbox.
    // Combining clearfix and flexbox on Safari causes issues.
    &.o-flex {
        *zoom: 1;

        &:before,
        &:after {
            display: none;
            content: '';
        }

        &:after {
            clear: none;
        }
    }
}

// Default gutter

.o-grid--gutter {
    @include margin-lr(-($grid-gutter),-($grid-gutter));
}

.o-grid--gutter-sm {
    @include margin-lr(-($grid-gutter/2),-($grid-gutter/2));
}

.o-grid--gutter-lg {
    @include margin-lr(-($grid-gutter),-($grid-gutter));
}

    // Grid item, takes the full width by default

    .o-grid__item {
        float: left;
        width: 100%;

        .o-grid--gutter > & {
            @include padding-lr($grid-gutter,$grid-gutter);
        }

        .o-grid--gutter-sm > & {
            @include padding-lr($grid-gutter/2,$grid-gutter/2);
        }

        .o-grid--gutter-lg > & {
            @include padding-lr($grid-gutter*2,$grid-gutter*2);
        }
    }
