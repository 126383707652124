// Hide browser sync notification
#__bs_notify__ { top:95% !important; right: 10px !important; border-radius: 15px !important; height:60px !important; display: none !important; }

// Vertical rhythm
html {
    position: relative;

    @if $show-breakpoint {
      &:before {
        content: '📱 Mobile (bp1)';
        @include font-size(.7);
        letter-spacing: .1rem;
        position: fixed;
        bottom: 0;
        left: 0;
        color: white;
        background-color: rgba(#000,.7);
        z-index: 9999;
        @include padding-lr(1,1);
        @include padding-tb(.5,.5);

        @include mq(bp2) {
          content: '📱 Mobile (bp2)';
        }

        @include mq(bp3) {
          content: '◽️ Tablet portrait (bp3)';
        }

        @include mq(bp4) {
          content: '⬜️ Tablet landscape (bp4)';
        }

        @include mq(bp5) {
          content: '🖥 Desktop (bp5)';
        }

        @include mq(bp6) {
          content: '🖥 Desktop (bp6)';
        }

        @include mq(bp7) {
          content: '🖥 Desktop (bp7)';
        }
      }
    }

    @if $show-baseline {
        &:after {
            content: '';
            display: block;
            position: absolute;
            z-index: 100;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            pointer-events: none;
            background-image: linear-gradient(to bottom, rgba(#FFF, .1) 1px, transparent 1px, transparent #{ .5 * $base-leading + 'px'}, rgba(#FFF, .2) #{ .5 * $base-leading + 'px'}, transparent #{ .5 * $base-leading + 1 + 'px'}, transparent #{$base-leading + 'px'});


            background-position: left top;
            background-repeat: repeat;
            background-size: 100% #{$base-leading + 'px'};
        }

        @if $adjust-font-size-responsive == true {
            @include mq($responsive-font-breakpoint, max) {
              &:after {
                background-image: linear-gradient(to bottom, rgba(red, .1) 1px, transparent 1px, transparent #{ .5 * $responsive-leading + 'px'}, rgba(red, .2) #{ .5 * $responsive-leading + 'px'}, transparent #{ .5 * $responsive-leading + 1 + 'px'}, transparent #{$responsive-leading + 'px'});
                background-size: 100% #{$responsive-leading + 'px'};
              }
            }
        }
    }
}

h1,
h2,
h3,
h4,
p,
li,
.c-table {
    @if $show-baseline {
        // background-color: rgba(red,.1);
        background-color: rgba(#f0e483,.2);
    }
}
