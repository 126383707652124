.c-footer {}

.c-footer__links {
    display: flex;
    justify-content: center;
    
    @include mq(bp3) {
        display: block;
    }
}

.c-footer__credit {
    display: flex;
    justify-content: center;

    @include mq(bp3) {
        justify-content: end;
    }
}