/*
    Elements: Page
    ---
    Default markup for page level elements
*/

html {
    font-family: $html-font-family;
    font-weight: $html-font-weight;
    font-size: #{$base-font-size + "px"};
    font-size: #{$base-font-size-percentage + "%"};
    -webkit-overflow-scrolling: touch;
    background-color: $html-background-color;

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
            font-size: #{$responsive-font-size + "px"};
            font-size: #{$responsive-font-size-percentage + "%"};
        }
    }
}

body {
    color: $body-color;

    @if $body-font-smoothing == true {
        -webkit-font-smoothing: antialiased;
    }
}

::-moz-selection {
    background-color: $body-selection-background-color;
    color: $body-selection-color;
    text-shadow: $body-selection-text-shadow;
}

::selection {
    background-color: $body-selection-background-color;
    color: $body-selection-color;
    text-shadow: $body-selection-text-shadow;
}
