/*
    Components: Row
    ---
    Creates a horizontal row with padding
*/


.c-row {
    position: relative;
    @include padding-tb(1,0);
    @include padding-lr($columns-gutter*1.5,$columns-gutter*1.5);

    @include mq(bp3) {
      @include padding-lr($columns-gutter*2,$columns-gutter*2);
    }

    @include mq(bp4) {
      @include padding-tb(1.5,.5);
      @include padding-lr($columns-gutter*7,$columns-gutter*7);
    }

    @include mq(bp7) {
      @include padding-tb(2,1);
    }
}

.c-row-equal {
    position: relative;
    @include padding-tb(1,1);
    @include padding-lr($columns-gutter,$columns-gutter);

    @include mq(bp4) {
      @include padding-tb(1.5,1.5);
    }

    @include mq(bp7) {
      @include padding-tb(2,2);
    }
}


/* =  Row sizes.
-------------------------------------------------------*/

.c-row--sm {
    // @include mq(bp4) {
      @include padding-tb(1,1);
    // }

    @include mq(bp7) {
      @include padding-tb(1.5,1.5);
    }

    & + & {
      @include padding-top(.5);
    }
}

.c-row--md {
    @include padding-tb(2,2);

    @include mq(bp2) {
      @include padding-tb(4,4);
    }
}

.c-row--lg {
    @include padding-tb(2,2);

    @include mq(bp3) {
      @include padding-tb(2.25,2.25);
    }
}

.c-row--xl {
  @include padding-tb(2.5,2.5);

  @include mq(bp3) {
    @include padding-tb(5,5);
  }
}


/* =  Row colors.
-------------------------------------------------------*/

.c-row--alpha {
    background-color: palette(color-neutral,base);
}

.c-row--beta {
    background-image: linear-gradient(to bottom right, palette(color-alpha,base), palette(color-alpha,x-light));
}

.c-row--neutral {
    color: palette(color-neutral,light);
    background-color: palette(color-neutral,xxx-light);
}


/* =  Row modifiers.
-------------------------------------------------------*/

// Row with an image only. Reset bottom margin, for equal spacing.
.c-row--image {
    .o-mask {
        @include margin-bottom(0);
    }
}


/* =  Specific rows.
-------------------------------------------------------*/

.c-row--header {
  border-bottom: 1px solid palette(color-white,xx-light);
  @include padding-tb(.5,.5);

  @include mq(bp2) {
    @include padding-tb(.75,.5);
  }

  @include mq(bp3) {
    @include padding-tb(1,.5);
  }

  @include mq($bp-mobile) {
    border-bottom: 0;
  }

  @include mq(bp6) {
    @include padding-tb(1.5,1.5);
  }

  // @include mq(bp7) {
  //   @include padding-tb(1.5,1.5);
  // }
}

.c-row-breadcrumbs {
    background-color: palette(color-neutral,xxx-light);
    @include padding-tb(.25,.25);
    @include padding-lr($columns-gutter,$columns-gutter);
}

.c-row--home-hero {
  @include padding-tb(1.75,0);

    @include mq(bp3) {
      @include padding-tb(2.5,0);
    }

    @include mq(bp4) {
      @include padding-tb(2.5,.5);
    }

    @include mq(bp6) {
      @include padding-tb(2.5,1.5);
    }
}

.c-row--case-hero {
  @include padding-tb(1,0);

    @include mq(bp3) {
      @include padding-tb(2.5,0);
    }

    @include mq(bp4) {
      @include padding-tb(2,0);
    }
}

.c-row-footer {
    background-color: palette(color-neutral,xxx-light);
    @include padding-tb(.25,.25);
    @include padding-lr($columns-gutter,$columns-gutter);
}
