/*
    Utilities: Spacing
    ---
    Utility classes for spacing
*/

// Loop through the increments map and get the value.
@each $increment, $value in $increments {

    // Increment utility class (e.g. .u-mt-alpha)
    .u-mt-#{$increment} {
        @include margin-top($value);
    }

    // Increment utility class (e.g. .u-mb-alpha)
    .u-mb-#{$increment} {
        @include margin-bottom($value);
    }

    // Increment utility class (e.g. .u-pt-alpha)
    .u-pt-#{$increment} {
        @include padding-top($value);
    }

    // Increment utility class (e.g. .u-pb-alpha)
    .u-pb-#{$increment} {
        @include padding-bottom($value);
    }
}

.u-plr-reset {
    padding-left: 0;
    padding-right: 0;
}

.u-plr-reset-untill-bp3 {
    @include mq(bp2,max) {
        padding-left: 0;
        padding-right: 0;
    }
}

.u-ptb-reset {
    padding-top: 0;
    padding-bottom: 0;
}

.u-p-reset {
    padding-top: 0;
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
}

.u-mt-neg-delta-bp5 {
    @include mq(bp3) {
        @include margin-top(- map-get($increments,delta));
    }

    @include mq(bp4) {
        @include margin-top(0);
    }

    @include mq(bp5) {
        @include margin-top(- map-get($increments,delta));
    }
}
