//
//  Default settings
//  ---
//
//  Global
//  ---
//  Colors
//  Modular scale
//  Font settings
//  UI (border-radius, -width, -style)
//  Z-indexes
//  Breakpoints
//  Grid
//  Increments
//  Animations
//
//  Element settings
//  ---
//  Page
//  Typography
//  Images
//  Forms
//  Tables
//
//  Object settings
//  ---
//  Grid
//  Media
//
//  Utilities
//  ---
//  Debug
//

// Set to false to hide the baseline and background
$show-baseline: false;
$show-breakpoint: false;

// ---
// Colors
// ---

// Palette function > eg. palette(color-alpha,base)
@function palette($palette, $tone: 'base') {
    @return map-get(map-get($palettes, $palette), $tone);
}

// Base colors
$color-neutral                      : #000 !default;
$color-white                        : #FFF !default;

$color-alpha                        : #0F5FDF !default;
$color-beta                         : #2ECC40 !default;

$color-error                        : #DC1E34 !default;
$color-success                      : #00B34D !default;
$color-notice                       : #FF851B !default;

// Palettes
// => Colors are defined in the Marlux styleguide.
$palettes: (
    color-neutral: (
        xx-light                    : rgba($color-neutral,.1),
        x-light                     : rgba($color-neutral,.2),
        light                       : rgba($color-neutral,.5),
        base                        : $color-neutral,
    ),
    color-white: (
        xx-light                    : rgba($color-white,.1),
        x-light                     : rgba($color-white,.2),
        light                       : rgba($color-white,.5),
        base                        : $color-white,
    ),
    color-alpha: (
        xx-light                    : hsl(hue($color-alpha),55,96),
        x-light                     : #9DD9E5,
        light                       : #79CFE3,
        base                        : $color-alpha,
        dark                        : #00A8D5,
        x-dark                      : #008EB3
    ),
    color-beta: (
        light                       : hsl(hue($color-beta),70,60),
        base                        : $color-beta,
        dark                        : hsl(hue($color-beta),100,25),
        x-dark                      : hsl(hue($color-beta),100,15)
    ),
    color-error: (
        light                       : hsl(hue($color-error),70,96),
        base                        : $color-error,
        dark                        : hsl(hue($color-error),100,35),
    ),
    color-success: (
        light                       : hsl(hue($color-success),70,50),
        base                        : $color-success,
        dark                        : hsl(hue($color-success),100,35),
    ),
    color-notice: (
        light                       : hsl(hue($color-notice),70,60),
        base                        : $color-notice,
        dark                        : hsl(hue($color-notice),100,35),
    )
) !default;


// ---
// Typographic settings
// ---

// Manual modular scale font sizes in em's
// based on http://www.modularscale.com/?18&px&1.2

$ms-4                               : 0.482 !default;
$ms-3                               : 0.579 !default;
$ms-2                               : 0.694 !default;
$ms-1                               : 0.833 !default;
$ms0                                : 1 !default;
$ms1                                : 1.2 !default;
$ms2                                : 1.44 !default;
$ms3                                : 1.728 !default;
$ms4                                : 2.074 !default;
$ms5                                : 2.488 !default;
$ms6                                : 2.986 !default;
$ms7                                : 3.583 !default;
$ms8                                : 4.3 !default;


$alpha-font-family                  : "LotaGrotesqueAlt3-Light", sans-serif !default;
$beta-font-family                   : "LotaGrotesqueAlt3-Regular", sans-serif !default;

$alpha-font-weight                  : 300 !default;
$beta-font-weight                   : 400 !default;


// Font size
$base-font-size-percentage          : 150 !default; // in %
$base-font-size                     : 16 * ($base-font-size-percentage / 100);

// Line-height + leading
$base-line-height                   : 1.5 !default;
$base-leading                       : round($base-font-size * $base-line-height) !default;


// (?) Adjust font-size on smaller screens
$adjust-font-size-responsive        : true !default;
$responsive-font-breakpoint         : bp6 !default;

$responsive-font-size-percentage    : 125 !default; // in %
$responsive-font-size               : 16 * ($responsive-font-size-percentage / 100);

$responsive-line-height             : 1.65 !default;
$responsive-leading                 : round($responsive-font-size * $responsive-line-height) !default;



// Needs deleting
$alpha-font-size                    : $ms0 !default;
$alpha-line-height                  : 1.5 !default;

// $alpha-leading                      : $base-font-size * $alpha-line-height !default;
// $alpha-leading                      : $alpha-line-height * .75 !default;
// $beta-leading                       : $alpha-line-height * 1.75 !default;

// ---
// UI
// ---

$radius                             : 2px !default;
$border-width                       : 1px !default;
$border-style                       : solid !default;


// ---
// Z-indexes
// ---

$z-indexes: (
    alpha                           : 1,
    beta                            : 2,
    gamma                           : 3,
    delta                           : 4,
    epsilon                         : 5
) !default;

// ---
// Breakpoints
// ---

$breakpoints: (
    bp1                             : 320/16*1em,
    bp2                             : 480/16*1em,
    bp3                             : 680/16*1em,
    bp4                             : 960/16*1em,
    bp5                             : 1140/16*1em,
    bp6                             : 1400/16*1em,
    bp7                             : 1600/16*1em,
) !default;

$bp-mobile: 480/16*1em;

// ---
// Grid
// ---

// .5 times the $base-leading. It's calculated in rem in _tools.spacing.scss
$columns-gutter                     : .5 !default;

// Define the amount of columns in the grid
$columns: (
    2,
    3,
    4,
    5,
    6,
    7,
    8,
    9,
    10,
    11,
    12
) !default;

// ---
// Increments
// ---

$increments: (
    alpha                           : 0,
    beta                            : .5,
    gamma                           : 1,
    delta                           : 1.5,
    epsilon                         : 2,
    zeta                            : 2.5,
    eta                             : 3,
    theta                           : 3.5,
) !default;

// ---
// Animations
// ---

$animation-duration-alpha           : .1s !default;
$animation-duration-beta            : .2s !default;
$animation-duration-gamma           : .3s !default;
$animation-easing-alpha             : ease-in-out !default;
$animation-easing-beta              : cubic-bezier(.68, -.55, .265, 1.55) !default; // bouncy

// ---
// Page
// ---

$html-font-family                   : $alpha-font-family !default;
$html-font-weight                   : $beta-font-weight !default;
// $html-font-size                     : $base-font-size !default;
$html-line-height                   : $alpha-line-height !default;

$html-responsive-font-size          : false !default;
$html-responsive-font-breakpoint    : bp5 !default;
$html-responsive-font-magnification : 112.5% !default;

$html-background-color              : palette(color-neutral) !default;
$body-color                         : $color-white !default;

$body-font-smoothing                : true !default;

$body-selection-background-color    : rgba(palette(color-alpha,light),.2) !default;
$body-selection-color               : palette(color-alpha,base) !default;
$body-selection-text-shadow         : none !default;

// ---
// Typography
// ---

$h1-font-family                     : $alpha-font-family !default;
$h1-font-weight                     : $alpha-font-weight !default;
$h1-font-size                       : $ms7 !default;
$h1-line-height                     : 3 !default;
$h1-margin-top                      : 0 !default;
$h1-margin-bottom                   : 1 !default;

$h2-font-family                     : $alpha-font-family !default;
$h2-font-weight                     : $alpha-font-weight !default;
$h2-font-size                       : $ms4 !default;
$h2-line-height                     : 1.5 !default;
$h2-margin-top                      : 0 !default;
$h2-margin-bottom                   : .5 !default;

$h3-font-family                     : $alpha-font-family !default;
$h3-font-weight                     : $alpha-font-weight !default;
$h3-font-size                       : $ms1 !default;
$h3-line-height                     : 1 !default;
$h3-margin-top                      : 0 !default;
$h3-margin-bottom                   : .5 !default;

$h4-font-family                     : $alpha-font-family !default;
$h4-font-weight                     : $alpha-font-weight !default;
$h4-font-size                       : $ms0 !default;
$h4-line-height                     : 1 !default;
$h4-margin-top                      : 0 !default;
$h4-margin-bottom                   : .5 !default;

$h5-font-family                     : $alpha-font-family !default;
$h5-font-weight                     : $alpha-font-weight !default;
$h5-font-size                       : $ms0 !default;
$h5-line-height                     : 1 !default;
$h5-margin-top                      : 0 !default;
$h5-margin-bottom                   : .5 !default;

$h6-font-family                     : $alpha-font-family !default;
$h6-font-weight                     : $alpha-font-weight !default;
$h6-font-size                       : $ms0 !default;
$h6-line-height                     : 1 !default;
$h6-margin-top                      : 0 !default;
$h6-margin-bottom                   : .5 !default;

// $base-font-size                     : $alpha-font-size !default;
// $base-line-height                   : 1 !default;
// $base-margin-bottom                 : $beta-leading !default;
$base-indent                        : 1.5 !default;

$blockquote-font-style              : italic !default;
$blockquote-font-size               : $ms1 !default;
$blockquote-line-height             : 1 !default;
$blockquote-margin-top              : 0 !default;
$blockquote-margin-bottom           : 1 !default;

$small-font-size                    : $ms-1 !default;
$small-line-height                  : 1 !default;
$small-margin-bottom                : .5 !default;

$horizontal-rule-border-width       : 1px 0 0 !default;
$horizontal-rule-border-style       : solid !default;
$horizontal-rule-border-color       : palette(color-white,x-light) !default;

$horizontal-rule-margin-top         : 0 !default;
$horizontal-rule-margin-bottom      : 1 !default;

$link-transition                    : none !default;

$link-decoration                    : none !default;
$link-decoration--hover             : none !default;

$link-color                         : $color-white !default;
$link-color--visited                : $color-white !default;
$link-color--hover                  : $color-white !default;
$link-color--focus                  : $color-white !default;

$link-outline                       : 0 !default;
$link-outline--focus                : thin dotted !default;

// ---
// Images
// ---

$img-max-width                      : 100% !default;
$img-border                         : 0 !default;

// ---
// Forms
// ---

$fieldset-border                    : 0 !default;
$fieldset-margin-bottom             : .5 !default;

$legend-font-size                   : $ms0 !default;
$legend-line-height                 : 1 !default;
$legend-margin-bottom               : .5 !default;

$label-font-size                    : $ms0 !default;
$label-line-height                  : 1 !default;
$label-margin-bottom                : .5  !default;

$placeholder-font-family            : $alpha-font-family !default;
$placeholder-font-style             : italic !default;
$placeholder-color                  : palette(color-neutral, light) !default;

$firefox-inner-border-fix           : true !default;

// ---
// Tables
// ---

$table-font-family                  : $alpha-font-family !default;
$table-align                        : left !default;

$caption-font-family                : $alpha-font-family !default;
$caption-font-weight                : $beta-font-weight;
$caption-align                      : center !default;
$caption-margin-bottom              : .5 !default;
$caption-padding-bottom             : .5 !default;

// ---
// Grid
// ---

$grid-max-width                     : map-get($breakpoints,bp4) !default;
$grid-gutter                        : $columns-gutter !default;

// ---
// Media
// ---

$media-object-gutter                : $columns-gutter !default;
$media-object-margin-bottom         : 1 !default;

// ---
// Media-embed
// ---

$media-embed-ratio                  : 62.5% !default;
$media-embed-margin-bottom          : 1 !default;

// ---
// Debug
// ---

$debug-mode                         : false !default; // toggles the debug mode
