/*
    Utilities: Modular scale
*/

.u-ms-3 {
    @include ms-3;
}

.u-ms-2 {
    @include ms-2;
}

.u-ms-1 {
    @include ms-1;
}

.u-ms0 {
    @include ms0;
}

.u-ms1 {
    @include ms1;
}

.u-ms2 {
    @include ms2;
}

.u-ms3 {
    @include ms3;
}

.u-ms4 {
    @include ms4;
}

.u-ms5 {
    @include ms5;
}

.u-ms6 {
    @include ms6;
}

// .u-ms7 {
//     @include ms7;
// }
//
// .u-ms8 {
//     @include ms8;
// }
//
// .u-ms9 {
//     @include ms9;
// }

// .u-ms10 {
//     @include ms10;
// }

// .u-ms11 {
//     @include ms11;
// }
//
// .u-ms12 {
//     @include ms12;
// }
//
// .u-ms13 {
//     @include ms13;
// }
