/*
    Components: Navigation
    ---
    Site navigation, breadcrumbs, etc.
*/

.c-nav-main {
    position: relative;
    width: 100%;
    float: left;
    display: none;
    font-family: $alpha-font-family;

    @include mq($bp-mobile) {
        display: block;
        width: auto;
        float: right;
    }

    &.is-visible {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 4em;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: calc(100vh - 4em);
        z-index: 1;
        @include padding-tb(1,1);
        @include padding-lr(1,1);
        background-color: palette(color-neutral);
        // border: 1px solid palette(color-neutral);
    }
}

    .c-nav-main__list {
        // border-top: 1px dotted palette(color-neutral,light);

        @include mq($bp-mobile) {
            border-top: 0;
            margin-bottom: 0;
        }
    }

    .c-nav-main__item {
        & + & {
            // border-top: 1px dotted palette(color-neutral,light);
        }

        @include mq($bp-mobile) {
            float: left;

            & + & {
                border-top: 0;
                @include margin-lr(1,0);
            }
        }
    }

    .c-nav-main__link {
        display: block;
        color: palette(color-white);
        text-decoration: none;
        font-weight: $alpha-font-weight;
        border-color: transparent;
        // @include text-dimensions($alpha-font-size,1,0,.25,.25);
        @include ms3;
        text-align: center;
        @include margin-bottom(2);


        @include mq($bp-mobile) {
            @include ms-1;
            text-align: right;
            @include margin-bottom(0);

            &:focus {
                border-bottom: 0;
            }
        }

        @include mq($bp-mobile) {
            &:hover,
            .is-selected & {
                // color: palette(color-alpha,dark);
                border-bottom: 1px solid palette(color-white,light);
            }
        }
    }

.c-nav-main-trigger {
    user-select: none;
    position: absolute;
    top: .8em;
    right: 1.5em;
    display: block;
    color: palette(color-white);
    background: palette(color-white,x-light);
    border: none;
    outline: 0;
    border-radius: $radius;
    font-family: $beta-font-family;
    font-weight: $beta-font-weight;
    @include padding-tb(.1,.1);
    @include padding-lr(.25,.25);
    @include ms-1;

    &:before {
        content: attr(data-content-default);
    }
    

    &.is-active {
        &:before {
            content: attr(data-content-close);
        }
    }

    @include mq($bp-mobile) {
        display: none;
    }
}
