/*
    Components: Icons
    ---
    UI icons
*/

.c-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    position: relative;
    top: 1px;
    background-repeat: no-repeat;
    background-position: center top;

    @include hide-text;
}

.c-icon--toggle {
    background-image: url('../img/png/toggle--sprite.png');

    .svg & {
        background-image: url('../img/svg/toggle--sprite.svg');
    }

    .is-toggled & {
        background-position: center -46px;
    }
}
