/*
    Utilities: Maxwidth
    ---
*/

.u-max-width-xs {
    max-width: #{15 * $base-font-size + 'px'};
    max-width: #{15 + 'rem'};
}

.u-max-width-sm {
    max-width: #{23 * $base-font-size + 'px'};
    max-width: #{23 + 'rem'};
}

.u-max-width-md {
    max-width: #{30 * $base-font-size + 'px'};
    max-width: #{30 + 'rem'};
}

.u-max-width-lg {
    max-width: #{40 * $base-font-size + 'px'};
    max-width: #{40 + 'rem'};
}

.u-max-width-xl {
    max-width: #{60 * $base-font-size + 'px'};
    max-width: #{60 + 'rem'};
}
