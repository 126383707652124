/*
    Shame
    ---
    Hacks and quick fixes
*/

table { 
    border-collapse: collapse; 
    width: 100%;
}

tr + tr { 
    border-top: 1px solid palette(color-white,x-light); 
}

td,
th { 
    // @include padding-tb(.75,.75);
    // @include padding-lr(.5,.5);

    @include mq(bp3) {
        @include padding-tb(.75,.75);
        // @include padding-lr(.5,.5);
    }
}

tr {
    @include mq(bp3,max) {
        display: block;
        @include padding-tb(.5,.5);
    }
}

td {
   

    @include mq(bp3,max) {
        width: 100%;
        display: block;
    }
}

// Animation
/*
.u-animate-up {
    position: relative;
    // transform: translate(0px, 0vh);
    animation-name: up;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    

    
}

@keyframes up {
    from {
        transform: translate(0px, 100vh);
    }
    to {
        transform: translate(0px, 0vh);
    }
}

.u-animate-title {
    transform: translateY(140%);
    transition: transform 1.3s cubic-bezier(.075, .82, .165, 1);

    

    .is-loaded & {
        transform: translateY(0%);
    }
}

.u-linemask {
    position: relative;
    // overflow: hidden;
    display: inline-block;

    &::after {
        content: '';
        position: absolute;
        
        right: 0;
        bottom: 0;
        left: 0;
        height: 100%;
        background-color: palette(color-neutral);
        // background-color: red;
        transform: translateY(60%);
        // opacity: .4;
    }
}
*/




// Extremis case styling.

.c-work-extremis {
    $theme-bg-color: #FFF;
    $theme-accent-color: palette(color-neutral);
    $theme-headline-color: palette(color-neutral);
    $theme-title-color: palette(color-neutral);
    $theme-text-color: palette(color-neutral);
    //palette(color-neutral)
    
    body {
        background-color: $theme-bg-color;
    }

    h1 {
        color: $theme-headline-color;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $theme-title-color;
    }

    p {
        color: $theme-text-color;
    }

    a {
        // text-decoration: $link-decoration;
        color: $theme-text-color;
        // transition: $link-transition;
        border-bottom: 1px solid palette(color-neutral,light);
    
        &:visited {
            color: $theme-text-color;
        }
    
        &:hover {
            text-decoration: $link-decoration--hover;
            color: $theme-text-color;
            border-bottom: 1px solid palette(color-neutral);
        }
    
        &:focus {
            outline: $link-outline--focus;
            color: palette(color-neutral)
        }
    
        &:hover,
        &:active {
            outline: $link-outline;
        }
    }

    hr {
        border-color: palette(color-neutral,xx-light)
    }

    .c-typo-lead {
        &:after {
            content: "";
            background-color: palette(color-neutral,light);
        }
    }


    .c-button--alpha {
        background-color: $theme-accent-color;
        border-color: $theme-accent-color;

        &,
        &:visited {
            color: palette(color-white);
        }

        &:hover,
        &:focus {
            background-color: palette(color-white);
            color: palette(color-neutral);
        }

        &:active {
            background-color: palette(color-alpha);
        }
    }

    .c-row--alpha {
        background-color: $theme-bg-color;
    }

    .c-row--header {
        border-bottom: 1px solid palette(color-neutral,xx-light);
      
        @include mq($bp-mobile) {
          border-bottom: 0;
        }
      }

    .c-link-hover {
        text-decoration: none;
        border-color: transparent;
        // border-bottom: 0;
    
        &:hover {
            text-decoration: none;
            border-bottom: 1px solid palette(color-neutral);
        }
    }

    .c-nav-main {    
        &.is-visible {
            background-color: palette(color-white);
        }
    }

    .c-nav-main__link {
        color: palette(color-neutral);
        border-color: transparent;

        &:hover,
        .is-selected & {
            border-color: transparent;
        }

        @include mq($bp-mobile) {
            &:hover,
            .is-selected & {
                border-bottom: 1px solid palette(color-neutral,base);
            }
        }
    }

    .c-nav-main-trigger {
        color: palette(color-neutral);
        background: palette(color-white);
        border: 1px solid palette(color-neutral,x-light);
    }
    
}


// RW case styling.

.c-work-rock-werchter {
    $theme-bg-color: #fff;
    $theme-accent-color: #67C4B9;
    $theme-headline-color: #232020;
    $theme-title-color: #232020;
    $theme-text-color: #232020;
    //palette(color-neutral)
    
    body {
        background-color: $theme-bg-color;
    }

    h1 {
        color: $theme-headline-color;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $theme-title-color;
    }

    p {
        color: $theme-text-color;
    }

    a {
        // text-decoration: $link-decoration;
        color: $theme-text-color;
        // transition: $link-transition;
        border-bottom: 1px solid palette(color-neutral,light);
    
        &:visited {
            color: $theme-text-color;
        }
    
        &:hover {
            text-decoration: $link-decoration--hover;
            color: $theme-text-color;
            border-bottom: 1px solid palette(color-neutral);
        }
    
        &:focus {
            outline: $link-outline--focus;
            color: palette(color-neutral)
        }
    
        &:hover,
        &:active {
            outline: $link-outline;
        }
    }

    hr {
        border-color: palette(color-neutral,xx-light)
    }

    .c-row--header {
        // background-image: url(../img/jpg/rw/rw-pattern-theme-01.svg);
        // background-size: contain;
        // background-size: 5px;
    }

    .c-typo-lead {
        &:after {
            content: "";
            background-color: $theme-accent-color;
        }
    }


    .c-button--alpha {
        background-color: $theme-accent-color;
        border-color: $theme-accent-color;

        &,
        &:visited {
            color: palette(color-white);
        }

        &:hover,
        &:focus {
            background-color: palette(color-white);
            color: palette(color-neutral);
        }

        &:active {
            background-color: palette(color-alpha);
        }
    }

    .c-row--alpha {
        background-color: $theme-bg-color;
    }

    .c-row--header {
        border-bottom: 1px solid palette(color-neutral,xx-light);
      
        @include mq($bp-mobile) {
          border-bottom: 0;
        }
      }

    .c-link-hover {
        text-decoration: none;
        border-color: transparent;
        // border-bottom: 0;
    
        &:hover {
            text-decoration: none;
            border-bottom: 1px solid palette(color-neutral);
        }
    }

    .c-nav-main {    
        &.is-visible {
            background-color: palette(color-white);
        }
    }

    .c-nav-main__link {
        color: palette(color-neutral);
        border-color: transparent;

        &:hover,
        .is-selected & {
            border-color: transparent;
        }

        @include mq($bp-mobile) {
            &:hover,
            .is-selected & {
                border-bottom: 1px solid palette(color-neutral,base);
            }
        }
    }

    .c-nav-main-trigger {
        color: palette(color-neutral);
        background: palette(color-white);
        border: 1px solid palette(color-neutral,x-light);
    }
    
}


// body {
//     animation: fadeInAnimation 350ms cubic-bezier(0.16, 1, 0.3, 1);
//     animation-iteration-count: 1;
//     animation-fill-mode: forwards;
// }

// @keyframes fadeInAnimation {
//     0% {
//         opacity: 0;
//     }
//     100% {
//         opacity: 1;
//      }
// }