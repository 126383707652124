/*
    Components: typography
    ---
    Typographic components
*/

.c-page-title {
    @include ms4;

    @include mq(bp3) {
        @include ms6;
    }

    @include mq(bp6) {
        font-family: $h1-font-family;
        font-weight: $h1-font-weight;
        @include font-size($h1-font-size);
        @include line-height($h1-line-height);
        @include margin-top($h1-margin-top);
        // @include margin-bottom(2);
    }
}

.c-page-title-lg {
    @include ms4;

    @include mq(bp3) {
        @include ms6;
        @include margin-bottom(2);
    }

    @include mq(bp6) {
        @include ms8;
        @include margin-bottom(3);
    }
}

.c-typo-lead {
    position: relative;
    color: palette(color-white);
    font-family: $beta-font-family;
    font-weight: $beta-font-weight;
    @include font-size($ms0);
    @include line-height(1);
    // @include margin-top(.5);
    @include margin-bottom(.75);

    @include mq(bp4) {
        @include margin-bottom(1.5);
    }
    
    &:after {
        content: "";
        position: absolute;
        width: 52px;
        height: 1px;
        bottom: 0;
        left: calc(50%);
        transform: translateX(-50%);
        @include margin-bottom(-.75);
        background-color: palette(color-white,light);

        @include mq(bp4) {
            width: 124px;
            @include margin-bottom(-1.5);
        }
    }
}

.c-typo-caption {
    color: palette(color-neutral);
    font-family: $alpha-font-family;
    @include font-size($ms-2);
    @include line-height(.5);
    @include margin-top(-1);
    @include margin-bottom(1);
}

.c-typo-uppercase {
    @include uppercase;
}

.c-typo-shadow {
    text-shadow: 2px 2px 5px rgba(black,.2);
}
