/*
    Components: Symbols
    ---
    https://css-tricks.com/svg-symbol-good-choice-icons/
*/

// Symbol
.c-symbol {
    display: inline-block;
    width: 1em;
    height: 1em;
    stroke-width: 0;
    stroke: currentColor;
    fill: currentColor;
    vertical-align: middle;
}

.c-symbol--md {
    width: 1.25em;
    height: 1.25em;
}

.c-symbol--lg {
    width: 1.5em;
    height: 1.5em;
}