/*
    Components: Links
    ---
    A custom link (with an icon, different color link, etc.)
*/

.c-link-muted {
    color: palette(color-white,light);
    border-bottom: 1px solid palette(color-white,x-light);
}

.c-link--icon {
    display: flex;
    align-items: center;

    .c-symbol {
        width: #{$base-font-size * 1.25 + "px"};
        height: #{$base-font-size * 1.25 + "px"};
        flex-shrink: 0;
    }
}

.c-link-hover {
    text-decoration: none;
    border-color: transparent;
    // border-bottom: 0;

    &:hover {
        text-decoration: none;
        border-bottom: 1px solid palette(color-white,light);
    }
}
