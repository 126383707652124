/*
    Utilities: Helpers
    ---
*/

/* =  Content helpers.
-------------------------------------------------------*/

.u-content-center {
    margin: 0 auto;
}

.u-text-right-bp3 {
    @include mq(bp3) {
        text-align: right;
    }
}

.u-typo-beta {
    font-family: $beta-font-family;
    font-weight: $beta-font-weight;
}

.u-content-hidden-accessible {
    @include hide-text;
}

// .u-mb-beta-untill

/* =  Position helpers.
-------------------------------------------------------*/

.u-pos-rel {
    @include mq(bp4) {
        position: relative;
    }
}


/* =  Border helpers.
-------------------------------------------------------*/

.u-reset-borders {
    border: 0;
}

/* =  Visibility helpers.
-------------------------------------------------------*/
.u-hidden-until-bp4 {
    @include mq(bp4,max) {
        display: none;
    }
}