/*
    Elements: typography
    ---
    Default markup for typographic elements
*/

h1 {
    font-family: $h1-font-family;
    font-weight: $h1-font-weight;
    @include font-size($h1-font-size);
    @include line-height($h1-line-height);
    @include margin-top($h1-margin-top);
    @include margin-bottom($h1-margin-bottom);
}

h2 {
    font-family: $h2-font-family;
    font-weight: $h2-font-weight;
    @include font-size($h2-font-size);
    @include line-height($h2-line-height);
    @include margin-top($h2-margin-top);
    @include margin-bottom($h2-margin-bottom);
}

h3 {
    font-family: $h3-font-family;
    font-weight: $h3-font-weight;
    @include font-size($h3-font-size);
    @include line-height($h3-line-height);
    @include margin-top($h3-margin-top);
    @include margin-bottom($h3-margin-bottom);
}

h4 {
    font-family: $h4-font-family;
    font-weight: $h4-font-weight;
    @include font-size($h4-font-size);
    @include line-height($h4-line-height);
    @include margin-top($h4-margin-top);
    @include margin-bottom($h4-margin-bottom);
}

h5 {
    font-family: $h5-font-family;
    font-weight: $h5-font-weight;
    @include text-dimensions($h5-font-size,$h5-line-height);
    @include margin-bottom($h5-margin-bottom);
}

h6 {
    font-family: $h6-font-family;
    font-weight: $h6-font-weight;
    @include text-dimensions($h6-font-size,$h6-line-height);
    @include margin-bottom($h6-margin-bottom);
}

// Set margins between headings
h1 + h2 {
  @include margin-top(1);
}

h2 + h3,
h3 + h4,
h4 + h5 {
  @include margin-top(.5);
}

h5 + h6 {
  @include margin-top(-.5);
}


p,
ol,
ul,
dl,
table,
address {
    @include line-height(1);
    @include margin-bottom(.5);
    // @include text-dimensions($base-font-size,$base-line-height);
    // @include margin-bottom($base-margin-bottom);
}

pre {
    tab-size: $base-indent;
}

ul,
ol,
blockquote {
    @include padding-left($base-indent);
    // padding-left: #{$base-indent}em;
}

li {
    > ul,
    > ol {
        margin-bottom: 0;
    }
}

blockquote {
    font-style: $blockquote-font-style;
    @include font-size($blockquote-font-size);
    @include line-height($blockquote-line-height);
    @include margin-top($blockquote-margin-top);
    @include margin-bottom($blockquote-margin-bottom);

    > p {
        font-size: inherit;
        line-height: inherit;
    }
}

small {
    @include text-dimensions($small-font-size,$small-line-height);
    @include margin-bottom($small-margin-bottom);
}

hr {
    border-width: $horizontal-rule-border-width;
    border-style: $horizontal-rule-border-style;
    border-color: $horizontal-rule-border-color;
    clear: both;
    @include margin-tb($horizontal-rule-margin-top,$horizontal-rule-margin-bottom);
}

// Links

a {
    text-decoration: $link-decoration;
    color: $link-color;
    transition: $link-transition;
    border-bottom: 1px solid palette(color-white,light);

    &:visited {
        color: $link-color--visited;
    }

    &:hover {
        text-decoration: $link-decoration--hover;
        color: $link-color--hover;
        border-bottom: 1px solid palette(color-white);
    }

    &:focus {
        outline: $link-outline--focus;
        color: $link-color--focus;
    }

    &:hover,
    &:active {
        outline: $link-outline;
    }
}
