.o-mask {
    display: block;
    position: relative;
    overflow: hidden;
    @include margin-bottom(.5);

    > picture,
    > video,
    > img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
    }

    > picture > img,
    > picture > video {
        width: 100%;
    }
}

/* =  Mask ratio's.
-------------------------------------------------------*/

.o-mask--ratio-case-img {
    padding-bottom: 61.98%;
}

// Regular
.o-mask--ratio-3x4 {
    padding-bottom: 133.33%;
}

.o-mask--ratio-1x2 {
    padding-bottom: 150%;
}

.o-mask--ratio-3x2 {
    padding-bottom: 66.66%;
}

.o-mask--ratio-16x9 {
    padding-bottom: 56.25%;
}

.o-mask--ratio-9x16 {
    padding-bottom: 177.77%;
}

.o-mask--ratio-4x3 {
    padding-bottom: 75%;
}

.o-mask--ratio-2x1 {
    padding-bottom: 50%;
}

.o-mask--ratio-3x1 {
    padding-bottom: 33.33%;
}

.o-mask--ratio-1x1 {
    padding-bottom: 100%;
}

.o-mask--ratio-16x5 {
    padding-bottom: 31.25%;
}

.o-mask--ratio-16x5 {
    padding-bottom: 31.25%;
}

.o-mask--ratio-1x1-bp3 {
    @include mq(bp3) {
        padding-bottom: 100%;
    }
}

.o-mask--ratio-2x1-bp3 {
    @include mq(bp3) {
        padding-bottom: 50%;
    }
}

.o-mask--ratio-3x2-bp4 {
    @include mq(bp4) {
        padding-bottom: 66.66%;
    }
}
