//
//  Tools: Spacing
//  ---
//  Mixins to add padding and margin in rem with $px-fallback
//

// Horizontal spacing

// horizontal padding based on the alpha font size in rem
// use: @include padding-lr(#left, #right);

@mixin padding-lr($left: $base-leading, $right: $left) {
    padding-left: #{ $left * $base-leading + 'px'};
    padding-left: #{ $left * ($base-leading / $base-font-size) + 'rem'};
    padding-right: #{ $right * $base-leading + 'px'};
    padding-right: #{ $right * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          padding-left: #{ $left * $responsive-leading + 'px'};
          padding-left: #{ $left * ($responsive-leading / $responsive-font-size) + 'rem'};
          padding-right: #{ $right * $responsive-leading + 'px'};
          padding-right: #{ $right * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin padding-left($left: $base-leading) {
    padding-left: #{ $left * $base-leading + 'px'};
    padding-left: #{ $left * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          padding-left: #{ $left * $responsive-leading + 'px'};
          padding-left: #{ $left * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin padding-right($right: $base-leading) {
    padding-right: #{ $right * $base-leading + 'px'};
    padding-right: #{ $right * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          padding-right: #{ $right * $responsive-leading + 'px'};
          padding-right: #{ $right * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

// horizontal margin based on the alpha font size in rem
// use: @include margin-lr(#left, #right);

@mixin margin-lr($left: $base-leading, $right: $left) {
    margin-left: #{ $left * $base-leading + 'px'};
    margin-left: #{ $left * ($base-leading / $base-font-size) + 'rem'};
    margin-right: #{ $right * $base-leading + 'px'};
    margin-right: #{ $right * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          margin-left: #{ $left * $responsive-leading + 'px'};
          margin-left: #{ $left * ($responsive-leading / $responsive-font-size) + 'rem'};
          margin-right: #{ $right * $responsive-leading + 'px'};
          margin-right: #{ $right * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin margin-left($left: $base-leading) {
    margin-left: #{ $left * $base-leading + 'px'};
    margin-left: #{ $left * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          margin-left: #{ $left * $responsive-leading + 'px'};
          margin-left: #{ $left * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin margin-right($right: 1) {
    margin-right: #{ $right * $base-leading + 'px'};
    margin-right: #{ $right * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          margin-right: #{ $right * $responsive-leading + 'px'};
          margin-right: #{ $right * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

// Vertical spacing

// vertical padding based on the base font size in rem
// use: @include padding-tb(#top, #bottom);

@mixin padding-tb($top: $base-leading, $bottom: 0) {
    padding-top: #{ $top * $base-leading + 'px'};
    padding-top: #{ $top * ($base-leading / $base-font-size) + 'rem'};
    padding-bottom: #{ $bottom * $base-leading + 'px'};
    padding-bottom: #{ $bottom * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          padding-top: #{ $top * $responsive-leading + 'px'};
          padding-top: #{ $top * ($responsive-leading / $responsive-font-size) + 'rem'};
          padding-bottom: #{ $bottom * $responsive-leading + 'px'};
          padding-bottom: #{ $bottom * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin padding-bottom($bottom: $base-leading) {
    padding-bottom: #{ $bottom * $base-leading + 'px'};
    padding-bottom: #{ $bottom * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          padding-bottom: #{ $bottom * $responsive-leading + 'px'};
          padding-bottom: #{ $bottom * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }

}

@mixin padding-top($top: $base-leading) {
    padding-top: #{ $top * $base-leading + 'px'};
    padding-top: #{ $top * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          padding-top: #{ $top * $responsive-leading + 'px'};
          padding-top: #{ $top * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

// vertical margin based on the alpha font size in rem
// use: @include margin-tb(#top, #bottom);

@mixin margin-tb($top: $base-leading, $bottom: 0) {
    margin-top: #{ $top * $base-leading + 'px'};
    margin-top: #{ $top * ($base-leading / $base-font-size) + 'rem'};
    margin-bottom: #{ $bottom * $base-leading + 'px'};
    margin-bottom: #{ $bottom * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          margin-top: #{ $top * $responsive-leading + 'px'};
          margin-top: #{ $top * ($responsive-leading / $responsive-font-size) + 'rem'};
          margin-bottom: #{ $bottom * $responsive-leading + 'px'};
          margin-bottom: #{ $bottom * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin margin-bottom($bottom: $base-leading) {
    margin-bottom: #{ $bottom * $base-leading + 'px'};
    margin-bottom: #{ $bottom * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          margin-bottom: #{ $bottom * $responsive-leading + 'px'};
          margin-bottom: #{ $bottom * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

@mixin margin-top($top: $base-leading) {
    margin-top: #{ $top * $base-leading + 'px'};
    margin-top: #{ $top * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          margin-top: #{ $top * $responsive-leading + 'px'};
          margin-top: #{ $top * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}
