//
//  Tools: Typography
//  ---
//  Typographic mixins
//

// Font-size
@mixin font-size($number) {
    // font-size: #{ $number * $base-font-size + 'px'};
    // font-size: #{ $number + 'rem'};
    font-size: #{round($number * $base-font-size) + 'px'};
    font-size: #{ $number + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          font-size: #{ $number * $responsive-font-size + 'px'};
          font-size: #{ $number + 'rem'};
        }
    }
}

// Line-height
@mixin line-height($number) {
    line-height: #{ $number * $base-leading + 'px'};
    line-height: #{ $number * ($base-leading / $base-font-size) + 'rem'};

    @if $adjust-font-size-responsive == true {
        @include mq($responsive-font-breakpoint, max) {
          line-height: #{ $number * $responsive-leading + 'px'};
          line-height: #{ $number * ($responsive-leading / $responsive-font-size) + 'rem'};
        }
    }
}

// Uppercase
@mixin uppercase() {
    text-transform: uppercase;
    letter-spacing: .1em;
}

// baseline formula based on the $alpha-line-height variable
// use: @include text-dimensions(#font-size, #line-height, #margin-bottom, #padding-top)
$baseline-unit: $alpha-font-size * $alpha-line-height;
$basefontpercent: $alpha-font-size/16 * 100;
$fs: 1;
$lh: 1;
@mixin text-dimensions($fs: $alpha-font-size, $lh: $alpha-line-height, $mb: 0, $pt: 0, $pb: 0, $mt: 0) {
    font-size: #{$fs / $alpha-font-size}rem;
    //line-height: #{($baseline-unit / $fs) * $lh}em;
    line-height: #{$alpha-line-height * $lh}rem;
    @if $mb > 0 {
        @include margin-bottom($mb);
    }
    @if $mt > 0 {
        @include margin-top($mt);
    }
    @if $pt > 0 {
        @include padding-top($pt);
    }
    @if $pb > 0 {
        @include padding-bottom($pb);
    }
}
@mixin input-dimensions($fs: 1, $lh: 1, $mb: 0, $pt: 0, $pb: 0, $mt: 0) {
    font-size: $fs/$alpha-font-size*1rem;
    line-height: #{$alpha-line-height * $lh}rem;
    @if $mb > 0 {
        @include margin-bottom($mb);
    }
    @if $mt > 0 {
        @include margin-top($mt);
    }
    @if $pt > 0 {
        @include padding-top($pt);
    }
    @if $pb > 0 {
        @include padding-bottom($pb);
    }
}
