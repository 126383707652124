/**
 * @license
 * MyFonts Webfont Build ID 4351762, 2022-02-07T14:11:08-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: LotaGrotesqueAlt3-Light by Los Andes
 * URL: https://www.myfonts.com/fonts/los-andes/lota-grotesque/alt-3-light/
 * 
 * Webfont: LotaGrotesqueAlt3-Regular by Los Andes
 * URL: https://www.myfonts.com/fonts/los-andes/lota-grotesque/alt-3-regular/
 * 
 * 
 * Webfonts copyright: Copyright © 2017 by Daniel Hernandez. All rights reserved.
 * 
 * © 2022 MyFonts Inc
*/


/* @import must be at top of file, otherwise CSS will not work */
// @import url("//hello.myfonts.net/count/426712");
  
@font-face {
  font-family: "LotaGrotesqueAlt3-Regular";
  src: url('../fonts/LotaGrotesqueAlt3Regular.woff2') format('woff2'), url('../fonts/LotaGrotesqueAlt3Regular.woff') format('woff');
}

@font-face {
  font-family: "LotaGrotesqueAlt3-Light";
  src: url('../fonts/LotaGrotesqueAlt3Light.woff2') format('woff2'), url('../fonts/LotaGrotesqueAlt3Light.woff') format('woff');
}

